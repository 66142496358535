import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { getWasteTypeGroupedEvents } from "../../../selectors/visitEdit";
import { WasteWeightsByWasteType } from "../WasteWeightsByWasteType";

import styles from "./WasteWeights.module.scss";

export const WasteWeights: React.FC = () => {
  const { t } = useTranslation();
  const groupedEvents = useSelector(getWasteTypeGroupedEvents);

  return (
    <div className={styles.container}>
      <h4 className={styles.title}>{t("wasteWeights.enterWasteSum")}</h4>
      {groupedEvents.map((events) => (
        <WasteWeightsByWasteType key={events[0].wasteTypeId} wasteTypeId={events[0].wasteTypeId} events={events} />
      ))}
      {groupedEvents.length === 0 && <div className={styles.selectFirstItem}>{t("wasteWeights.selectFirstItem")}</div>}
    </div>
  );
};
