import { IVisitApi, IVisitEdit, IVisitEvent } from "../../types/visitEdit";
import { randomNumberId } from "../../utils/id";

export const adaptResponseBodyForFrontend = (responseBody: IVisitApi): IVisitEdit => {
  const apiVisit = responseBody;
  return {
    visitId: apiVisit.collection_yard_visit_id,
    wasteTypes: apiVisit.waste_types,
    wasteSum: apiVisit.waste_sum,
    created: apiVisit.created,
    events: apiVisit.CollectionYardEvents.map((apiEvent: any) => ({
      eventId: randomNumberId(),
      apiEventId: apiEvent.collection_yard_event_id,
      wasteTypeId: apiEvent.waste_type_id,
      brutto: apiEvent.brutto === 0 ? "" : apiEvent.brutto,
      tara: apiEvent.tara === 0 ? "" : apiEvent.tara,
      unfinished: apiEvent.unfinished,
    })),
  };
};

export const adaptRequestBodyFromBackend = (
  events: IVisitEvent[],
  removedEventsIds: number[],
  locationId: number,
  visitId?: number
) => {
  const apiEvents = events.map((event) => ({
    collection_yard_event_id: event?.apiEventId,
    waste_type_id: event.wasteTypeId,
    brutto: Number(event.brutto),
    tara: Number(event.tara),
    unfinished: event.unfinished,
  }));

  const removedApiEvents = removedEventsIds.map((eventId) => ({
    collection_yard_event_id: eventId,
  }));

  return {
    location_id: locationId,
    collection_yard_visit_id: visitId,
    events: [...apiEvents, ...removedApiEvents],
  };
};
