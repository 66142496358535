export const API_URL = process.env.REACT_APP_CITIZEN_API_URL || "https://api.citizen.ewama.k42.dev/v1";

export const POST_LOGIN_URL = "/collection-yards/login";
export const GET_LOGOUT_URL = "/collection-yards/logout";
export const GET_REFRESH_AUTH_TOKEN_URL = "/collection-yards/refresh";

export const GET_COLLECTION_YARD_URL = "/collection-yards";
export const POST_LOCATIONS_LIST_URL = "/collection-yards/locations";

export const POST_LOCATION_DETAIL_VISITS_URL = "/collection-yards/visits";
export const POST_QR_CODE_VERIFY_URL = "/collection-yards/qr/verify";
export const GET_VISIT_URL = "/collection-yards/visit";
export const POST_VISIT_URL = "/collection-yards/visit";

export const SUPPORT_EMAIL = "info@wama.sk";

export const HOME_PAGE_PATH = "/";
export const LOGIN_PAGE_PATH = "/login";
export const LOCATION_DETAIL_PAGE_PATH = "/location-detail";
export const VISIT_EDIT_PAGE_PATH = "/location-detail/visit-edit";
